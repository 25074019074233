
import { createTheme } from '@mui/material/styles';

const colours = {
  background: "#fff",
  primary: "#294238",
  primaryLight: "#85a99d",
  primaryDark: "#003421",
  secondary: "#a1cb88",
  text: "#282a2e",
  contrastText: "#fff",

  grey1: "#aaa",
}

// https://mui.com/material-ui/customization/default-theme/
const theme = createTheme({
  palette: {
    primary: {
      main: colours.primary,
      light: colours.primaryLight,
      dark: colours.primaryDark,
    },
    secondary: {
      main: colours.primaryDark,
    },
    text: {
      primary: colours.text,
    }
  },
  typography: {
    fontFamily: [
      'Lexend', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'].join(','),
    fontSize: 12,
    h2: {
      fontSize: 24,
      fontWeight: 600,
    },
    h3: {
      fontSize: 16,
      // fontWeight: 600,
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      fontWeight: 700,
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
      color: colours.primary,
    }
  },

});

export default theme;

export { colours };
