import {Typography} from "@mui/material";
import "./Button.css"

/**
 * Standard button. Red with white text by default. `variant` prop can be set to `"outline"` for
 * red outline and transparent background.
 */
const Button = ({ onClick, label, variant, disabled, ...props }) => {
  let disabledClass = ""
  if (disabled) {
    disabledClass = "Button-disabled"
  }

  if (!variant) {
    variant = ""
  } else {
    variant = `Button-${variant}`
  }

  return (
    <button className={["Button", variant, disabledClass].join(" ")} onClick={onClick} {...props}>
      <Typography>{label}</Typography>
    </button>
  )
}

export default Button;
