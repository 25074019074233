import { useState } from "react";
import {Box, Collapse, Stack} from '@mui/material'
import Notification, {NotificationProps} from "../Notification/Notification";


interface IdentifiableError extends Error {
  id: string;
}

interface NotificationCenterProps {
  notifications: NotificationProps[];
  errors: IdentifiableError[]; // Each error has unique id
}


function parseError(error: IdentifiableError) {
  let message = error.message
  let title: string

  if (message === "Failed to fetch") {
    title = "Fetch error";
    message = "Couldn't connect to API to download data";
  }
  // Generalising all other back-end errors
  else {
    title = "Internal server error";
    message = "The server encountered an internal error and was unable to complete your request";
  }

  return { message, title }
}


const NotificationCenter = ({ notifications, errors, }: NotificationCenterProps) => {

  const [open, setOpen] = useState(true)
  const [invisibleNotifications, setInvisibleNotifications] = useState<string[]>([])
  const [invisibleErrors, setInvisibleErrors] = useState<string[]>([])

  return (
    <Box style={{ width: 500, position: "absolute", bottom: 5, right: 5, zIndex: 999 }}>
      <Collapse in={open}>
        {
          notifications.filter(d => !invisibleNotifications.includes(d.id)).map((notification) => (
            <Notification
              key={notification.id}
              // message={notification.}
              {...notification}
              // severity={"error"}
              // message={message}
              onClose={() => {
                setInvisibleNotifications(prevState => [...prevState, notification.id])
              }}
            />
          ))
        }
        {errors.filter(e => !invisibleErrors.includes(e.id)).map((error)  => {

          const { message, title } = parseError(error)

          return (
            <Notification
              messageTitle={title}
              id={error.id}
              key={error.name}
              severity={"error"}
              message={message}
              onClose={() => {
                setInvisibleErrors(prevState => [...prevState, error.id])
              }}
            />
          )
        })}
      </Collapse>
    </Box>
  )
}

export default NotificationCenter;
