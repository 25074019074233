import {useCallback, useEffect, useState, useMemo, useRef} from "react";
import {Stack, Typography} from "@mui/material";
import {getAuth} from "firebase/auth";

import {get, uploadFile} from "./js/interface";

import Dropzone from "./components/Dropzone/Dropzone";
import SignInScreen from "./components/SignInScreen/SignInScreen";
import Profile from "./components/Profile/Profile";
import NotificationCenter from "./components/NotificationCenter/NotificationCenter";
import Button from "./components/Button/Button";
import Divider from "./components/Divider/Divider";
import NavBar from "./components/NavBar/NavBar";
import ChipArray from "./components/ChipArray/ChipArray";
import ProgressPanel from "./components/ProgressPanel/ProgressPanel";

console.log("version: v1.0.0")

// logEvent({eventName: "appLoaded"})

function App() {

    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    const [page, setPage] = useState("home");

    const [notifications, setNotifications] = useState([]);
    const [errors, setErrors] = useState([]);

    const selectedFile = useRef();
    const [file, setFile] = useState(null);
    const isFileSentStatus= useRef(false);
    const [isFileSent, setIsFileSent] = useState(false);
    const [filesToDownload, setFilesToDownload] = useState([]);

    const [houstonStatus, setHoustonStatus] = useState("Ready");
    const [pipelineProgress, setPipelineProgress] = useState("noFileSelected");
    const initialFetchDone = useRef(false);

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const fileSizeLimit = 10000000; // 10MB file size limit



    const handleError = useCallback(error => {

        // Handle validation errors
        if (error.errors) {
            const numValErrors = error.errors.length;
            console.log("Number of validation errors: ", numValErrors)

            error.errors.forEach((err, i) => {
                notify({
                    messageTitle: "Validation Error",
                    id: `valError${i}${new Date().getTime()}`,
                    message: err,
                    severity: "error"
                })
            })
        }
        // Handle any other error
        else {
            console.log("non-validation error encountered: ", error)
            error.id = new Date().getTime()
            setErrors(prevState => [error, ...prevState.filter(e => e.id !== error.id)]);
        }
        // logEvent({eventName: "error", error: error.toString()})
    }, [])

    const notify = (notification) => {
        console.log("Notification:", notification)
        setNotifications(prevState => [...prevState, notification])
    }

    const onFileAdded = (files) => {
        if (files[0].size > fileSizeLimit) {
            notify({
                messageTitle: "Size limit",
                id: `fileSizeLimit${new Date().getTime()}`,
                message: "The file exceeds maximum file size",
                severity: "warning"
            })
        } else {
            setIsFileSent(false);
            setPipelineProgress("fileSelected");
            setFile(files[0]);
        }
    };

    const submitFile = (f) => {
        if (f !== null) {

            const auth = getAuth();
            auth.currentUser.getIdToken().then((idToken) => {

                setIsSubmitDisabled(true);
                setPipelineProgress("validationInProgress");
                uploadFile("/upload", f, idToken, (res) => {
                    console.log("uploaded file:", f)
                    notify({
                        messageTitle: "Success",
                        id: `uploadSuccess${new Date().getTime()}`,
                        message: `File <b>${res.filename}</b><br>has successfully passed data validation!`,
                        severity: "success"
                    });
                    setPipelineProgress("forecastInProgress");

                }, (error) => {
                    handleError(error)
                    setIsSubmitDisabled(false);
                    setPipelineProgress("noFileSelected");
                })
                setIsFileSent(true);
            })
        } else {
            notify({
                messageTitle: "No file selected",
                id: `noFileWarning${new Date().getTime()}`,
                message: "You have not selected any file to upload",
                severity: "warning"
            })
            setIsSubmitDisabled(false);
        }
    }

const downloadFile = (fileName) => {
    const auth = getAuth();
    auth.currentUser.getIdToken().then((idToken) => {
        console.log(`File ${fileName} selected to be downloaded.`);
        get("/download", idToken, (blob) => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");


            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);

            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                })
            );
            document.body.removeChild(link);

            // After downloading the file, call the tests status endpoint
            get("/forecast/output/tests", idToken, (testResults) => {
                // Display notification based on test results
                let { filename, test_status, tests_results } = testResults; // These keys match the end point
                console.log(testResults)

                if (filename === fileName){
                    if (test_status === 'success') {
                        notify({
                            messageTitle: "Success",
                            id: `testStatus${new Date().getTime()}`,
                            message: `File <b>${filename}</b><br> has successfully passed output tests!`,
                            severity: "success"});
                }
                else if (test_status === 'failure')  {
                    // List the errors in seperate notifications
                    let results = JSON.parse(tests_results);
                    results.forEach((testResult, i) => {
                        if(testResult.test_name !== "baseline_weekly_monthly_site_calculations") {
                            notify({
                                messageTitle: `Test Warning`,
                                id: `testFailure${i}${new Date().getTime()}`,
                                message: `${testResult.message} in file<br><b>${filename}</b>`,  // Display each message
                                severity: "warning"
                        });
                        }
                    });
                }
                }
            }, (error) => {
                handleError(error);
            });

        }, (error) => {
            // Handle any download errors
            handleError(error);
        }, {file_name: fileName});
    })
}


    // Checks whether Houston is available to start a new mission
    const fetchServiceStatus = () => {
        const auth = getAuth();
        auth.currentUser.getIdToken().then((idToken) => {
                get("/forecast/status", idToken, (resp) => {
                    setHoustonStatus(resp.houston.is_houston_available ? "Ready" : "Busy");
                    setIsSubmitDisabled(!resp.houston.is_houston_available);

                    //
                    // Update the status for concurrent users who just opened the page
                    //

                    if (!resp.houston.is_houston_available && pipelineProgress === 'noFileSelected') {
                        setPipelineProgress("forecastInProgress");
                    }

                    // If Houston is available and the user did not select any file
                    if (resp.houston.is_houston_available && selectedFile.current === null && !isFileSentStatus.current) {
                        setPipelineProgress("noFileSelected");
                    }

                }, (error) => {
                    handleError(error)
                    setIsSubmitDisabled(false)
                })
            }
        )
    }



    useEffect(() => {
        // Update references for 'file' and 'isFileSent' states, so we can read their current values in 'fetchServiceStatus' interval
        selectedFile.current = file;
        isFileSentStatus.current = isFileSent;

        if (isFileSent) {
            // Clear the file once is sent
            setFile(null);
        }
    }, [isFileSent, file]);


    // Initial fetch of forecast files in the bucket
    useEffect(() => {
        const auth = getAuth();
        if (!auth.currentUser) {
            return;
        }
        auth.currentUser?.getIdToken().then((idToken) => {
            console.log("Signed in! Token is", idToken)

            get("/forecasts", idToken, (files) => {
                const currentNoFiles = filesToDownload.length;
                setFilesToDownload(files.forecasts);

                // If the new file arrived in the bucket after running the pipeline, this means a new forecast has been
                // generated, so send a success notification to the user
                if (initialFetchDone.current && files.forecasts.length > currentNoFiles) {
                    notify({
                        messageTitle: "Success",
                        id: `uploadSuccess${new Date().getTime()}`,
                        message: "A new forecast file has been generated!",
                        severity: "success"
                    });
                    setPipelineProgress("forecastAvailable");
                }

                // Mark initial fetch as completed
                initialFetchDone.current = true;

            }, (error) => {
                setPipelineProgress("noFileSelected");
                handleError(error);
            });
        });
    }, [isSignedIn, isSubmitDisabled]);


    // Fetches service status every 2 seconds, clearing up any previous fetch requests
    useEffect(() => {
        const interval = setInterval(fetchServiceStatus, 2000);
        return () => {
            clearInterval(interval);
        };
    }, []);


    return (
        <div className="App" style={{width: "100vw", height: "100vh", overflow: "hidden", overflowY: "auto"}}>
            <Stack direction={"column"} style={{width: "100%", height: "100%"}}>

                <NavBar>
                    {isSignedIn && <Profile />}
                </NavBar>

                <NotificationCenter notifications={notifications} errors={errors} />

                <SignInScreen handleSignInOut={setIsSignedIn} />

                {isSignedIn && (
                    <Stack alignItems={"center"}>
                        <Stack style={{ width: 720, paddingTop: 20, marginTop: 60 }} spacing={2}>
                            <Typography variant={"h1"}>Winter Forecast</Typography>
                            <Typography>Welcome to the Ground Control Winter Forecast service!</Typography>
                            <Typography>Please upload your sites
                                file and click `Generate Forecast` to create a new forecast.</Typography>
                            <Divider />
                            <Typography variant={"h2"}>
                                Service status: <span style={{ fontWeight: "lighter" }}>{houstonStatus}</span>
                            </Typography>
                            <ProgressPanel currentStage={pipelineProgress} />
                            <Typography variant={"h2"}>File upload</Typography>
                            <Dropzone onFileAdded={onFileAdded} file={file}/>
                            <Button label={"Generate Forecast"} disabled={isSubmitDisabled} onClick={() => {
                                setNotifications([])
                                setErrors([])
                                submitFile(file)
                            }} />
                            <br/>
                            <br/>
                            <Typography variant={"h2"}>Forecast download</Typography>
                            <Typography>Forecasts generated in the last 24hrs available to download (most recent first)</Typography>
                            <Typography>Downloading the most recent file will run output tests</Typography>
                            <ChipArray list={filesToDownload} onDownload={downloadFile} />
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </div>
    );
}


export default App;
