import {Chip, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import InsertLinkIcon from '@mui/icons-material/InsertLink';


interface ChipData {
    list: Array<string>;
    onDownload: (filename: string) => void;
}

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));


const ChipArray = ({ list, onDownload }: ChipData) => {

    return (
    <Paper sx={{
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0,
        m: 0,
      }}
      elevation={0}
      component={'ul'}>
        {list.map((fileName, index) => (
            <ListItem key={index}>
                <Chip
                    clickable={true}
                    icon={<InsertLinkIcon />}
                    label={<Typography>{fileName}</Typography>}
                    onClick={() => onDownload(fileName)}
                />
            </ListItem>
        ))}
    </Paper>
    );
}

export default ChipArray;