import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import * as firebaseui from "firebaseui";

import {Stack} from "@mui/material";
import "./SignInScreen.css"

// GCP project is in the URL when hosted by App Engine
let project = window.location.host.split(".")[0]

let apiKey;
if (project === "ground-control" || project.includes("prod")) {
  apiKey = "AIzaSyA5jEG242GhJ_xk5vaRNNwbqrdfMPxTkzI";
} else if (project.includes("test")) {
  apiKey = "AIzaSyD1yWH2LbsDTG2kI4CVRye2wb6Mo4oBx2g";
} else {
  project = "gctrl-winter-dev"
  apiKey = "AIzaSyCC6o2QdG_TEwvkaUKZedpjgCrTgClYENE";
}

// Configure Firebase.
const config = {
  apiKey,
  authDomain: project + ".firebaseapp.com"
};

const app = initializeApp(config);

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  signInOptions: [
    { provider: "password", disableSignUp: {status: true }},
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

const ELEMENT_ID = "firebaseui_container";

/**
 * See docs/authentication for more information.
 * Note: There is a bug that prevents this from working initially, see docs/authentication.md for more information.
 * Note: The sign in component must stay mounted the entire time.
 *       The DOM element will be hidden when the user is signed in.
 * Note: The 'sign out' button is a separate component imported directly into App.
 */
const SignInScreen = ({ handleSignInOut }) => {
  const firebaseAuth = getAuth(app)
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  // workaround from: https://github.com/firebase/firebaseui-web-react/issues/172
  React.useEffect(() => {
    // Get or Create a firebaseUI instance.
    const firebaseUiWidget =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebaseAuth);

    if (uiConfig.signInFlow === "popup") firebaseUiWidget.reset();

    // We track the auth state to reset Firebase UI if the user signs out.
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
      if (!user && isSignedIn) firebaseUiWidget.reset();
      setIsSignedIn(!!user)
    });

    // Render the Firebase UI Widget.
    firebaseUiWidget.start("#" + ELEMENT_ID, uiConfig);

    return () => {
      unregisterAuthObserver();
      firebaseUiWidget.reset();
    };
  }, [firebaseAuth, isSignedIn]);

  useEffect(() => {
    handleSignInOut(isSignedIn);
  }, [isSignedIn, handleSignInOut])

  return (
    <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} style={isSignedIn ? null : { height: "100%", width: "100%" }}>
      <div hidden={isSignedIn} style={{ background: "white", borderRadius: 20, padding: 20, minWidth: 300, width: "25vw" }}>
        <div className={"SignInScreen"} id={ELEMENT_ID} />
      </div>
    </Stack>
  );
}

export default SignInScreen;
