import React from 'react'

import classNames from "classnames";
import { useDropzone } from 'react-dropzone'

import './Dropzone.css'

function Dropzone(props) {
  const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
  } = useDropzone({
      accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
      maxFiles: 1,
      onDrop: props.onFileAdded
  });

  // Dynamically +/- styling classes
  const className = classNames(
      'baseStyle',
      {
          'focusedStyle': isFocused,
          'acceptStyle': isDragAccept,
          'rejectStyle': isDragReject
      }
  )


  return (
    <section className="container">
      <div {...getRootProps({ className })}>
        <input {...getInputProps()} />
        <p style={{ textAlign:"center" }}>Drag 'n' drop the sites data here, or click here to select it <br />(Max 10MB)</p>
      </div>
      <aside>
          {props.file && (
              <>
              <h4>Selected file</h4>
              <ul><i><li>{props.file.path} - {props.file.size} bytes</li></i></ul>
              </>
          )}
      </aside>
    </section>
  );
}

export default Dropzone