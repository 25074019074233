import {useState} from "react";

import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import Fab from '@mui/material/Fab';

import { Typography } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';


const ProgressBox = ({ index, current, label }) => {
        const isStageCompleted = index > current;

        // TODO: When currentStep === 4 the panel should disappear and we should show wireframe no.3

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', gap: 2, width: '120px', zIndex: -1 }}>
                {current === index && current !== 1 && current !== 4 ? (
                    <CircularProgress size={56} />
                ) : (
                    <Fab sx={{ pointerEvents: 'none' }} size="large" color="primary" disabled={isStageCompleted}>
                        <CheckIcon />
                    </Fab>
                )}
                <Typography>{label}</Typography>
            </Box>
        );
    };

export default function ProgressPanel( {currentStage}) {

    const statusMapping =  {
        "noFileSelected": 0,
        "fileSelected": 1,
        "validationInProgress": 2,
        "forecastInProgress": 3,
        "forecastAvailable": 4
    }

    const current = statusMapping[currentStage];

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box sx={{ m: 3, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 5, width: '100%' }}>
                <ProgressBox index={1} current={current} label="Input file selected" />
                <ProgressBox index={2} current={current} label="File validation is running" />
                <ProgressBox index={3} current={current} label="Forecast pipeline is running" />
                <ProgressBox index={4} current={current} label="New forecast available" />
            </Box>
        </Box>
    )
}