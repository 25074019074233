import {colours} from "../../theme";
import {Stack} from "@mui/material";

import logo from "../../images/gc_logo.png";

const NavBar = ({ children }) => (
  <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}
         style={{
           position: "fixed", width: "calc(100% - 40px)",
           background: colours.primary, color: colours.contrastText,
           backgroundImage: `url(${logo})`,
           backgroundPosition: "10px",
           backgroundRepeat: "no-repeat",
           backgroundSize: "contain",
           height: 20,
           padding: 20,
           zIndex: 0
         }}>
    {children}
  </Stack>

)

export default NavBar;