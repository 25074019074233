import {getAuth} from "firebase/auth";


// const BASE_URL = window.location.hostname === "localhost" ? "https://PROJECT_ID.nw.r.appspot.com/api"
const BASE_URL = window.location.hostname === "localhost" ? "/api"
    : window.location.hostname === '0.0.0.0' ? "/api"  // local API serving local build of app
        : `https://${window.location.hostname}/api`

const parseResponse = res => {
    const contentType = res.headers.get("content-type");

    // Header for file download
    if (contentType && contentType.includes('text/csv')) {
        return res.blob();
    }
    else {
        return res.json().then(json => {
           if (res.status >= 300) {
              // check for validation errors
              if (typeof json.val_errors !== "undefined") {
                  throw { errors: json.val_errors }
              }
              else {
                console.log("Catching >= 300 response", res)
                throw new Error("Received an error from the server: " + res.statusText, { cause: "Bad response from " + res.url })
              }
          }
           else {
             return json
          }
      })
    }
}

/**
 * @param path - Path to the backend endpoint
 * @param token - Auth firebase token
 * @param callback - Promise resolved callback function
 * @param onError - Promise rejected callback function
 * @param params - dictionary of parameters to include in the URL
 */
export const get = (path, token, callback, onError, params= {}) => {
    if (navigator.onLine) {
        const queryString = new URLSearchParams(params).toString();
        const url = queryString ? `${BASE_URL + path}?${queryString}` : BASE_URL + path;

        return fetch(url, {
            headers: new Headers({
                "Authorization": `Bearer ${token}`
            }),
        })
            .then(parseResponse)
            .then(callback)
            .catch(onError)
    } else {
        return onError(new Error("No internet connection"))
    }
}

/**
 * @param path - Path to the backend endpoint
 * @param data - Data to pass in the request body
 * @param token - Auth firebase token
 * @param callback - Promise resolved callback function
 * @param onError - Promise rejected callback function
 * @param params - dictionary of parameters to include in the URL
 */
export const post = (path, data, token, callback, onError, params= {}) => {
    if (navigator.onLine) {
        const queryString = new URLSearchParams(params).toString();
        const url = queryString ? `${BASE_URL + path}?${queryString}` : BASE_URL + path;

        return fetch(url, {
            method: "POST",
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(data)
        })
            .then(parseResponse)
            .then(callback)
            .catch(onError)
    } else {
        return onError(new Error("No internet connection"))
    }
}

export const delete_ = (path, token, callback, onError) => {
    return fetch(BASE_URL + path, {
        method: "DELETE",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
        }),
    })
        .then(parseResponse)
        .then(callback)
        .catch(onError)
}

export const uploadFile = (path, data, token, callback, onError) => {
    if (navigator.onLine) {

        const formData = new FormData();
        formData.append('file', data)

        return fetch(BASE_URL + path, {
            method: "POST",
            headers: new Headers({
                "Authorization": `Bearer ${token}`
            }),
            body: formData
        })
            .then(parseResponse)
            .then(callback)
            .catch(onError)
    } else {
        return onError(new Error("No internet connection"))
    }
}

// TODO: Bring it back once analytics endpoint in fastapi will be implemented
// export const logEvent = (eventData) => {
//   let token = ""
//   try {
//     // this will not work if user isn't signed in
//     token = getAuth().currentUser.auth.currentUser.accessToken
//   } catch {
//     // pass
//   }
//   return post("/analytics", eventData, token, () => null, () => null)
// }
